<template>
  <section>
    <kn-table
      v-if="!showInactive"
      :headers="headers"
      :items="items"
      :loading="loading"
      tableTitle="Proveedores"
      newButtonLabel="Agregar proveedor"
      :showBottomAction="true"
      @action2="disableSupplier"
      @showAction="showInactive = true"
    />
    <kn-inactive-items-table
      v-if="showInactive"
      :headers="headers"
      :items="inactiveItems"
      tableTitle="Proveedores"
      newButtonLabel="Agregar proveedor"
      :showBottomAction="true"
      @action2="enableSupplier"
      @showAction="showInactive = false"
    /> 
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import KnTable from '@/components/tables/KnTable.vue'
import { apiMixin } from '@/mixins/apiMixin.js'
import KnInactiveItemsTable from '@/components/tables/KnInactiveItemsTable.vue'
export default {
  components: { KnTable, KnInactiveItemsTable },
  mixins: [apiMixin],
  data() {
    return {
      groups: [],
      suppliers: [],
      headers: [
        { text: 'Nombre o Razón social', value: 'razon_social', class: 'purple--text' },
        { text: 'Contacto', value: 'contacto', class: 'purple--text' },
        { text: 'Categoría', value: 'categoria', class: 'purple--text' },
        { text: 'Subcategoría', value: 'sub_categoria', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      search: null,
      showInactive: false,
      loading: true
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    this.suppliers = await this.fetchResultsByEI('personas', 'proveedor', this.institutionId )
    await this.getSuppliers()
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getSuppliers() {
      try {
        this.suppliers.forEach(supplier => {
          let tradeName = ''
          let fullName = ''
          if (supplier.nombre_proveedor) {
            tradeName = supplier.nombre_proveedor
          } else if (supplier.datos_fiscales && supplier.datos_fiscales.length > 0) {
            tradeName = supplier.datos_fiscales[0].nombre_o_razon_social
          } else {
            const {
              first_name: fn,
              last_name: ln
            } = supplier.usuario
            if (fn !== '' || ln !== '') {
              tradeName = `${fn} ${ln}`
            } else {
              tradeName = 'Sin nombre'
            }
          }
          
          if (supplier.datos_personales !== null) {
            const {
              primer_nombre: pn,
              segundo_nombre: sn,
              apellido_paterno: ap,
              apellido_materno: am
            } = supplier.datos_personales
            fullName = `${pn} ${sn} ${ap} ${am}`
          } else {
            fullName = 'Sin contacto'
          }
          
          let categories = []
          let subcategories = []
          supplier.categorias.forEach(cat => {
            categories.push(cat.dato)
          })
          supplier.sub_categorias.forEach(subcat => {
            subcategories.push(subcat.dato)
          })
          
          const item = {
            razon_social: tradeName,
            contacto: fullName,
            categoria: categories.join(', '),
            sub_categoria: subcategories.join(', '),
            id: supplier.id,
            ...supplier
          }
          if (item.estatus_sistema) {
            this.items.push(item)
          } else {
            this.inactiveItems.push(item)
          }
        });
      } catch (error) {
        console.error('Error al obtener proveedores. ', error);
      }
    },
    /**
     * Desactiva un proveedor
     * @param {number} supplierId Id del proveedor a eliminar
     */
    async disableSupplier(supplierId) {
      try {
        const supplier = this.items.find(s => s.id === supplierId)
        const response = await this.postObj('/personas/delete-proveedor', {
          id_proveedor: supplierId
        },
        'eliminar')
        if (response.mensaje) {
          this.items = this.items.filter(s => s.id !== supplierId)
          this.inactiveItems.push(supplier)
        }
      } catch (error) {
        // console.log('Error al intentar desactivar proveedor', error);
      }
    },
    /**
     * Reactiva un proveedor
     * @param {number} supplierId Id del proveedor a reactivar
     */
    async enableSupplier(supplierId) {
      try {
        const supplier = this.inactiveItems.find(e => e.id === supplierId)
        const response = await this.postObj('/personas/un-delete-proveedor', {
          id_proveedor: supplierId
        },
        'reactivar')
        if (response.mensaje) {
          this.inactiveItems = this.inactiveItems.filter(e => e.id !== supplierId)
          this.items.push(supplier)
        }
      } catch (error) {
        console.error('Error al intentar reactivar un proveedor', error);
      }
    }
  }
}
</script>
